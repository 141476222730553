.card {
  background-color: $black;
}

.board-card {
  padding: 0 40px 0px 40px;
  background-color: $board-color;
  width: 100%;
  min-height: calc(100vh - 76px);

  border-radius: 40px 40px 0 0;
  left: 0;
  .board-content {
    height: auto;
    width: 496px;
    margin: 40px auto;
    padding-bottom: 100px;
    .head {
      i {
        color: #666666;
      }
    }
    .content {
      margin-top: 30px;
    }
    .head-profile {
      width: 72px;
      height: 72px;
      border-radius: 36px;
      position: relative;
      left: 50%;
      transform: translate(-36px, 0);
      margin: 0 auto;
      display: inline-block;
    }
    .info-area {
      background-color: $disable-color;
      height: 36px;
      width: 200px;
      border: 1px solid $disable-color;
      border-radius: 8px;
      padding: 0 12px;
      display: inline-flex;
    }
    .head-facade {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      vertical-align: middle;
      margin: auto 0;
    }
    .name-facade {
      margin: auto 10px;
    }
  }
}
.board-content-onboarding {
  height: auto;
  width: 100%;
  max-width: 880px;
  margin: 60px auto;
}

.board-content-secondary {
  padding: 3px 0 30px 0;
}

.board-equal-row {
  width: auto;
  padding: 0;
  margin: 0 -30px;
  display: flex;
  justify-content: space-between;
}
.board-col-primary {
  width: 100%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 30px;
  .form-row {
    .form-group {
      height: 36px;
    }
    label {
      margin: 0;
      height: 36px;
      padding-top: 20px;
    }
  }
  .col-ver-tips {
    width: 100%;
    height: 100%;
    background: rgba(255, 226, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    color: #ffe200;
    font-size: 14px;
  }
}

.sidebar-container {
  width: 260px;
  border: 1px solid #363636;
  height: auto;
  .sidebar-upper-form {
    padding: 15px 30px;
  }
  .description-part {
    border-top: 1px dashed #363636;
    padding: 14px 30px;
    .label-text {
      font-weight: 800;
      color: $cream-50;
    }
  }
}
