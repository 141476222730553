// upload包裹层样式
.upload-component {
  .preview-wrap {
    width: 72px;
    margin: 0 auto;
    position: relative;
    .upload-input {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}
// modal弹框样式
.modal-dialog.upload-comps-modal {
  width: 550px;
  max-width: 550px;
  .modal-content {
    background-color: #121217;
    border: 1px solid #363636;
    border-radius: 8px;
    padding: 0 16px;
    width: 100%;
    .cropper-modal {
      background-color: #121217;
    }
  }

  .upload-header {
    margin: 12px;
    text-align: center;
    color: rgba(233, 233, 233, 0.5);
    font-size: 18px;
  }
  .opt-btn {
    display: flex;
    margin: 16px auto;
    width: 400px;
    .btn {
      width: 121px;
      height: 40px;
      margin-right: 14px;
      border: 2px solid rgba(233, 233, 233, 0.25);
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      &.confirm {
        color: #ed5f65;
        margin-right: 0;
        flex: 1;
      }
    }
  }
}
