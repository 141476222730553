.text-regular {
  font-size: 14px;
  color: $cream-75 !important;
  line-height: 1.5;
  font-weight: $regular;
  &.selected {
    color: $cream-100 !important;
  }
}

.text-field {
  font-weight: $bold;
  font-size: 14px;
  color: $cream-100 !important;
  line-height: 1.3;
}

.text-placehoder {
  font-weight: $bold;
  font-size: 14px;
  color: $cream-25 !important;
  line-height: 1.3;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-28 {
  font-size: 28px;
}
.f-32 {
  font-size: 32px;
}
.f-36 {
  font-size: 36px;
}
.f-72 {
  font-size: 72px;
}

.semi-bold {
  font-weight: $semi-bold;
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $bold;
}

.text-red {
  color: $red !important;
}

.text-yellow {
  color: $yellow !important;
}
.text-gray {
  color: $gray !important;
}
.text-blue {
  color: $primary !important;
}
.text-green {
  color: $green !important;
}
.text-orange {
  color: $orange !important;
}

.text-cream-25 {
  color: $cream-25 !important;
}
.bg-cream-25 {
  background: $cream-25 !important;
}
.text-cream-50 {
  color: $cream-50 !important;
}
.text-cream-75 {
  color: $cream-75 !important;
}
.text-cream-100 {
  color: $cream-100 !important;
}

.text-day-disabled {
  color: $cream-25 !important;
}

.line-height-1 {
  line-height: 1;
}
