.ant-picker {
  background-color: $field-color;
  border-color: $field-color;
  border-radius: 8px;
  color: white;
  input {
    color: white;
  }
  .ant-picker-clear {
    color: white;
    background-color: $field-color;
  }
}

.ant-picker-suffix {
  color: $cream-25;
}
