.form-row {
  margin: 0 -5px;
  > [class*='col-'] {
    padding: 5px;
    margin: 0;
  }
  > .col {
    padding: 5px;
    margin: 0;
    &.title {
      height: 36px;
    }
  }
}

.login-form {
  width: 320px;
  margin: 0 auto;
  h1 {
    margin-bottom: 40px;
  }
}

.form-group {
  margin: 0;
  width: auto;
  float: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: $regular;
}

.form-group-lg {
  margin-bottom: 15px !important;
  .form-control-lg {
    height: 60px;
    padding: auto 15px;
    font-size: 18px;
  }
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: none;
  padding: 0.375rem 0.75rem;
}

.form-control {
  border-radius: 8px;
  border: 1px solid $field-color;
  font-size: 14px;
  font-weight: $regular;
  color: $white;
  height: 36px;
  padding: auto 15px;
  background-color: $field-color;
  outline: none;
  height: auto;
  &:focus,
  &:hover {
    outline: none;
    color: $white;
    box-shadow: none;
    border: 1px solid $white;
    background-color: $board-color;
  }

  &:disabled {
    background-color: $disable-color;
    border: 1px solid $disable-color;
    height: 36px;
    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
      border-color: $disable-color;
    }
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $cream-25;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $cream-25;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $cream-25;
  }

  + .form-control {
    margin-top: 0.7rem;
  }
}

.open-hour-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  .py-5px {
    padding: 5px 0;
  }
  .from,
  .to {
    width: 93px;
    height: 36px;
    line-height: 36px;
  }
  .btn-hours {
    display: inline-table;
  }
  .time-picker-primary {
    border: none;
    height: 36px;
    line-height: 36px;
    // input {
    //   border: 1px solid $gray-500;
    //   border-radius: 4px;
    //   outline: none;
    //   padding: 0 12px;
    //   &:focus,
    //   &:active,
    //   &:hover {
    //     border-color: $primary;
    //   }
    // }

    .select__control {
      .select__indicators {
        display: none;
      }
    }
    .time-picker-options {
      position: absolute;
      width: 88px;
      min-height: 0px;
      z-index: 99;
      padding: 0 0;
      box-shadow: 0 6 10 rgba($gray-400, 0.1);
      background-color: $black;
      .options-list {
        max-height: 300px;
        overflow-y: auto;
        padding: 5px 0;
      }
    }
    .time-picker-option {
      padding: 0;
      margin: 0;
      height: 35px;
      width: 100%;
      border: none;
      border-radius: 0;
      font-size: 12px;

      &:focus,
      &:active,
      &:hover {
        background-color: $field-color;
        color: white;
      }

      .btn-timepicker:focus,
      .btn-timepicker:hover {
        background-color: $field-color;
        color: $white;
        border: none;
      }
    }
    .time-picker-setclose {
      height: 36px;
      width: 100%;
      border: none;
      padding: 0;
      font-size: 14px;
      background-color: white;
      color: $primary;
      border-top: 1px solid $gray-500;
    }
  }
  .day-label {
    height: 100%;
    width: 80px;
    line-height: 36px;
    margin: auto 0;
    color: $cream-50;
  }
  .closed {
    height: 36px;
    font-size: 14px;
    color: $cream-50;
    font-weight: $bold;
    text-align: center;
  }
  .text-to {
    font-size: 14px;
    color: $cream-50;
    font-weight: $regular;
    padding: 0 15px;
  }
  .time-picker {
    width: 225px;
    margin: 0 0;
  }
  .btn {
    // vertical-align: baseline;
    color: $cream-75;
    padding: 0;
    display: inline;
    margin: 0 0;
  }

  // & + .open-hour-row {
  //   margin-top: 4px;
  // }

  .label {
    color: $dark;
    font-size: 14px;
  }
  .open-hour-timepicker {
    max-width: 220px;
  }
}

.select-form-primary {
  width: 100%;
  height: 36px;
  padding: 0px 15px;
  border-radius: 8px;
  background-color: $field-color;
  border-color: $field-color;
  display: inline-flex;
  justify-content: space-between;
  box-shadow: none;
  font-size: 14px;
  &:hover,
  &:focus,
  &:active {
    border-color: $white;
    box-shadow: none;
  }
  &.onboarded {
    background-color: $board-color;
    border-color: $board-color;
    pointer-events: none;
    color: white;
    &.selected {
      background-color: $disable-color;
      border-color: $disable-color;
      pointer-events: none;
      .price,
      .name {
        color: white;
      }
    }
    .name {
      color: $cream-50;
    }
  }
  &.selected {
    background-color: $board-color;
    border-color: $white;
  }

  .name {
    color: $white;
    line-height: 36px;
    padding: 0 10px;
  }
  .price {
    line-height: 36px;
    padding: 0 10px;
    color: $cream-50;
  }
}

.select-form-primary-dot {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  display: inline-block;
  margin: auto 0;

  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.orange {
    background-color: $orange;
  }
}

.owner-form {
  justify-content: space-between;
  display: flex;
  .owner-25 {
    width: calc(50% - 5px);
    padding: 0;
  }
}

.exclamation {
  position: absolute;
  left: -10px;
  top: 18px;
  color: $red;
  &.time-picker-exclamation {
    left: -15px;
  }
}

.onboardings-filter,
.live-page-filter-group {
  padding: 20px 0 32px 0;
  .basic-single {
    width: 100%;
  }
}

.loc_add_query {
  z-index: 1;
}

.date-form {
  width: 230px;
  height: 36px;
  display: flex;
  align-items: center;
  background-color: #292a33;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  justify-content: space-between;
  color: rgba(233, 233, 233, 0.25);
}

.form-group-content-primary {
  position: relative;
  width: 100%;
  min-height: 36px;
  height: auto;
  padding: 5px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  background-color: $disable-color;
  border: 1px solid $disable-color;
  font-size: 14px;
  font-weight: $regular;
  color: $cream-25;
  text-overflow: ellipsis;
  overflow: hidden;
  &.form-with-icon {
    .icon-box {
      width: 16px;
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
    }
    i {
      font-size: 14px;
      margin: auto;
    }
  }
  &.selected {
    color: $cream-100;
  }
}

.cuisine-type {
  height: 23px;
  padding: 0 10px;
  margin: 2px 10px 2px 0;

  border: 1px solid #363636;
  border-radius: 4px;
  font-size: 10px;
}

.form-control-link {
  width: 100%;
  height: 100%;
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.input-with-trail {
  position: relative;
  height: auto;
  .trail {
    font-weight: $bold;
    color: $cream-50;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
  }
}

.daterange-container {
  top: 38px;
  transform: scale(0.8);
  transform-origin: 0 0;
  padding: 10px;
  background-color: #292a33;
  border-radius: 20px;
  .date-range {
    padding: 10;
    .rdrDateRangeWrapper {
    }
    .rdrDefinedRangesWrapper {
      background-color: #292a33;
      color: $white;
    }
    .rdrStaticRange {
      background-color: #292a33;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        background-color: $gray;
        color: $gray;
      }
    }
    .rdrCalendarWrapper {
      background-color: #292a33;
      .rdrDateDisplayWrapper {
        background-color: #292a33;
        .rdrDateDisplay {
          .rdrDateInput {
            background-color: #292a33;
            color: white;
          }
          .rdrDateDisplayItemActive,
          .rdrDateDisplayItem {
            input {
              color: white;
            }
          }
        }
      }
    }
    .rdrMonthAndYearPickers {
      .rdrMonthPicker,
      .rdrYearPicker {
        select {
          color: white;
          option {
            color: black;
          }
        }
      }
    }
    .rdrDays {
      .rdrDayDisabled {
        background-color: #1f2027;
        .rdrDayNumber {
          span {
            color: $cream-50;
          }
        }
      }
      .rdrDayToday {
      }
    }
    .rdrDayPassive {
      .rdrDayNumber {
        span {
          color: $cream-25;
        }
      }
    }
    .rdrDayNumber {
      span {
        color: $cream-100;
      }
    }
    .rdrInputRangeInput {
      color: white;
    }
  }
}

input:focus-visible {
  outline-offset: 0px;
  outline: 0px !important;
}

.general-input-container {
  .general-input {
    color: white;
    padding-left: 15px;
  }
  .suffix {
    top: 8px;
    right: 15px;
    color: #e9e9e9;
    opacity: 50%;
  }
}
