.ant-picker-dropdown {
  .ant-picker-panel-container {
    background: #1f2027;
    .ant-picker-panel {
      border: 0px;
      border-radius: 10px;
      .ant-picker-month-panel,
      .ant-picker-quarter-panel,
      .ant-picker-year-panel {
        background: #1f2027;
        .ant-picker-cell-inner {
          color: white !important;
        }
        .ant-picker-header {
          .ant-picker-header-view {
            color: white;
          }
          .ant-picker-header-super-prev-btn {
            color: white;
          }
          .ant-picker-header-super-next-btn {
            color: white;
          }
        }
      }
    }
  }
}
