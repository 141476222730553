.basic-single,
.basic-multi-select {
  border: none;

  .select__control {
    background-color: $field-color;
    border-color: $field-color;
    cursor: pointer;
    .select__input {
      color: $white;
    }
  }
  .select__control--is-focused,
  .select__control--is-focused:hover,
  .select__control--menu-is-open,
  .select__control--menu-is-open:hover {
    border-color: $white;
    background-color: $black;
    .select__value-container--is-multi {
      .select__multi-value {
        .select__multi-value__label {
          background-color: $black;
        }
      }
    }
  }
  .select__control:hover {
    border-color: $white;
  }
  .select__control--menu-is-open,
  .select__control--menu-is-open:hover {
    border-bottom: 1px solid $black;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select__control--is-disabled {
    background-color: #121217;
    border-color: #121217;
  }

  .select__single-value {
    color: white;
  }
  .select__menu {
    .select__menu-list {
      .select__option {
        cursor: pointer;
      }
      .select__option:hover,
      .select__option--is-focused {
        background-color: $field-color;
      }
    }
  }
  &.menu-z2 {
    .select__menu {
      z-index: 2;
    }
  }
  .select__indicators {
    // display: none;
  }
  .select__multi-value--is-disabled {
    .select__multi-value__remove {
      display: none;
    }
    .select__multi-value__label {
      padding-right: 4px;
    }
  }
}

.disabled {
  pointer-events: none;
  .select__control {
    background-color: $disable-color;
    border-color: $disable-color;
    color: $white;
  }
  .select__indicator {
    display: none;
  }
  .select__single-value {
    color: white;
  }
}

.basic-multi-select {
  &.checkbox {
    height: 36px;
    width: 100%;
  }

  .select__option {
    padding-left: 33px;
    position: relative;
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00c';
      display: inline-block;
      padding-right: 3px;
      vertical-align: middle;
      font-weight: $regular;
      font-size: 14px;
      position: absolute;
      left: 13px;
      top: 25%;
      color: transparent;
    }
  }
  .select__option--is-selected {
    &::before {
      color: $green;
    }
  }
}

.multi-select-primary {
  position: relative;
  .multi-select-showtext {
    position: absolute;
    z-index: 0;
    background-color: $field-color;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    &.menu_is_open {
      background-color: transparent;
    }
    .multi-select-showtext-content {
      margin: auto 15px;
    }
  }
  .basic-multi-select {
    .select__control {
      background-color: transparent;
      .select__placeholder {
        color: transparent;
      }
      .select__multi-value__label {
        display: none;
      }
      .select__multi-value {
        display: none;
      }
    }
  }
}

.multi-select-dropdown-indicator {
  position: absolute;
  right: 15px;
  color: $cream-50;
}

.basic-single-success {
  .select__single-value {
    color: $green;
  }
  &.with-header {
    .select__value-container--has-value {
      padding-left: 45px;
    }
  }
}

.basic-single {
  &.with-header {
    .select__value-container--has-value {
      padding-left: 45px;
    }
    .select__single-value { 
      width: 120px;
    }
  }
}

.select-with-header {
  position: relative;
  height: auto;
  .header {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    width: 22px;
    height: 22px;
    border-radius: 11px;
    display: inline-block;
    justify-content: center;
    i,
    img {
      width: 22px;
      height: 22px;
      align-self: center;
      font-size: 22px;
      display: flex;
      border-radius: 50%;
    }
  }
}

.custom-option {
  cursor: pointer;
  &:hover {
    background-color: $field-color;
  }
}
