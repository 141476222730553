.upload-doc {
  padding: 10px 0;
  .upload-zone {
    height: 36px;
    width: 100%;
    padding: 0 15px;
    border-radius: 8px;
    background-color: $field-color;
    &.inactive {
      background-color: $disable-color;
      label {
        color: white;
      }
    }
    .upload-area {
      height: 100%;
      width: 100%;
      margin: auto;
      vertical-align: middle;
      line-height: 36px;
      font-weight: $regular;
      cursor: pointer;
    }
  }
}
