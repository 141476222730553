.main-nav-dropdown {
  .nav-link {
    padding: 0;
    color: $cream-50;
  }
  &.selected {
    .nav-link {
      color: $cream-100;
    }
  }

  .dropdown-menu {
    background-color: $board-color;
    border: 1px solid $board-color;
    padding: 10px 0;
    .dropdown-item {
      font-size: 14px;
      color: $cream-100;
      padding: 5px 15px;
      &:hover,
      &:focus {
        background-color: $cream-25;
      }
    }
  }
}
.dropdown-item:focus {
  background-color: $cream-25;
}
