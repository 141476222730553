.primary-table {
  min-height: 666px;
  .table-responsive {
    overflow-y: hidden;
    min-height: 666px;
  }
  .border-table {
    border: 1px solid #363636;
    border-radius: 16px;
  }
  .table {
    margin-bottom: 0;
    th,
    td {
      padding: 10px 15px;
      border-top: none;
      white-space: nowrap;
      &.fixed-width {
        overflow: hidden;
        text-overflow: ellipsis;
        &.fixed-120 {
          max-width: 120px;
        }
        &.fixed-200 {
          max-width: 200px;
        }
      }
    }
    thead {
      border-bottom: 1px dashed #363636;
    }
    thead th {
      color: $cream-50;
      border-bottom: none;
      font-size: 12px;
      font-weight: $bold;
      white-space: nowrap;
    }
    tbody tr:first-child {
      td {
        margin-top: 10px;
      }
    }
    tbody tr:last-child {
      td {
        margin-bottom: 120px;
      }
    }
    .table-status-td {
      width: 104px;
      position: relative;
      padding-right: 0;
      padding-left: 20px;
      .requires_approval {
        width: 72px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #e9e9e9;
        font-size: 20px;
        background-color: rgba(233, 233, 233, 0.16);
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        span {
          transform: scale(0.5);
        }
        .requires_approval-border {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #e9e9e9;
        }
      }
    }
    .table-link-td {
      &:hover {
        color: $primary;
      }
    }
    .table-intiate-td,
    .table-onboard-td,
    .table-intiate-th,
    .table-onboard-th {
      padding: 10px 20px;
    }
    tbody td {
      color: $cream-75;
      border-bottom: none;
      font-size: 14px;
      font-weight: $regular;
      .table-status {
        color: $white;
        width: 120px;
        float: right;
        display: flex;
        .table-status-span {
          width: 4px;
          height: 16px;
          border-radius: 2px;
          margin: auto 8px auto 0;
          vertical-align: middle;
          display: inline-block;
          &.draft {
            border: 1px solid rgba(233, 233, 233, 0);
            background-color: rgba(233, 233, 233, 0);
          }
          &.signup_ready {
            border: 1px solid $blue;
            background-color: $blue;
          }
          &.withdrawn {
            border: 1px solid $blue;
          }
          &.signup_done {
            border: 1px solid $purple;
            background-color: $purple;
          }
          &.done {
            border: 1px solid $green;
            background-color: $green;
          }
          &.canceled {
            border: 1px solid $red;
            background-color: $red;
          }
          &.active {
            border: 1px solid $green;
            background-color: $green;
          }
          &.disqualified {
            border: 1px solid $red;
            background-color: $red;
          }
        }
      }

      .table-plan {
        color: $white;
        white-space: nowrap;
        .table-plan-span {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin: auto 10px auto 0;
          vertical-align: middle;
          display: inline-block;
          &.blue {
            border: 1px solid $blue;
            background-color: $blue;
          }
          &.green {
            border: 1px solid $green;
            background-color: $green;
          }
          &.orange {
            border: 1px solid $orange;
            background-color: $orange;
          }
        }
      }
    }
  }

  .table-hover tbody tr:hover {
    background-color: $field-color;
    cursor: pointer;
    td {
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .table-brand-td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-location-td {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-team-td {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-closer-td {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .referral-select {
    padding-left: 32px !important;
    padding-top: 11px !important;
    i {
      font-size: 17.5px;
    }
    .fa-check-square {
      color: #03c9a9;
    }
    .fa-square {
      color: rgba(233, 233, 233, 0.75);
    }
  }
  .table-owner {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    i {
      font-size: 14px;
      margin-right: 10px;
      color: $cream-50;
    }
    &.checked {
      i {
        color: $green;
      }
    }
  }

  &.table-small {
    min-height: 0;
    .table-responsive {
      min-height: 0;
    }
    .table {
      th {
        padding: 8px 5px;
        font-size: 10px;
        font-weight: $regular;
        color: $white;
      }
      td {
        padding: 6px 5px;
        pointer-events: none;
        font-size: 14px;
        font-weight: $regular;
        color: $white;
      }
    }
  }
  .seven-day {
    cursor: pointer;
    display: flex;
    .seven-day-icon {
      display: flex;
      align-items: center;
      .fa-sort-up {
        position: relative;
        left: 7.5px;
      }
    }
  }
}

.dashed-line {
  content: '';
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #363636,
    #363636 4px,
    transparent 4px,
    transparent
  );
  background-size: 8px 100%;
  &.margin-top-10 {
    margin-top: 10px;
  }
  &.margin-bottom-10 {
    margin-bottom: 10px;
  }
  &.margin-bottom-20 {
    margin-bottom: 20px;
  }
}

.invalid-value {
  text-decoration: line-through;
}
.status-span {
  width: 4px;
  height: 16px;
  border-radius: 2px;
  margin: auto 8px auto 0;
  vertical-align: middle;
  display: inline-block;

  &.signup_done {
    border: 1px solid $purple;
    background-color: $purple;
  }
  &.done {
    border: 1px solid $green;
    background-color: $green;
  }
}

.churn-span {
  display: inline-flex;
  width: 28px;
  height: 22px;
  color: $red;
  border: 1px solid $separator-color;
  border-radius: 4px;
  font-size: 12px;
  justify-content: center;
  line-height: 22px;
}

.comments {
  position: relative;
  .comments-pop {
    position: absolute;
    top: 0;
    left: -450px;
    max-height: 300px;
    width: 464px;
    padding: 0 32px;
    border-radius: 16px;
    background-color: #1c1c21;
    overflow: auto;
    .comments-pop-text {
      padding: 16px 0;
      border-top: 1px dashed #363636;
      font-size: 14px;
      color: rgba(233, 233, 233, 0.75);
      text-align: left;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}

.loaderboard-list-table {
  tbody {
    position: relative;
    top: 16px;
    .userAvatar {
      .log-header {
        width: 20px;
        height: 20px;
        i {
          font-size: 20px;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    td {
      .columns-rank {
        height: 20px;
        .text-blue {
          position: relative;
          bottom: 3px;
        }
      }
    }
  }
  .table-responsive {
    min-height: 486px;
    padding-bottom: 110px;
    position: relative;
  }
  .selected-period-footer {
    width: 100%;
    height: 90px;
    position: absolute;
    bottom: 0;
    border-top: 1px dashed #363636;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .spf-goal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .spf-goal-num {
        font-size: 24px;
        color: #e9e9e9;
        font-size: 700;
      }
      .spf-goal-text {
        color: #e9e9e9bf;
        font-size: 12px;
      }
    }
  }
}

.leaderboard-detail-table {
  .table-responsive {
    border-radius: 16px;
    min-height: 671px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    .table-status-td {
      left: 35px;
      padding-left: 0;
      text-align: initial;
    }
    .mw-180px {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .support-od-table-pagination {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.leaderboard-canceled-detail-table {
  margin-top: 32px;
  .table-responsive {
    min-height: 82px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: initial;
    .table-status-td {
      left: 35px;
      padding-left: 0;
      text-align: initial;
    }
    .mw-180px {
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.leaderboard-left-table {
  tbody tr {
    td {
      &:first-child {
        padding-left: 32px !important;
      }
      &:last-child {
        padding-right: 32px !important;
      }
    }
  }
  thead tr {
    th {
      padding: 16px 13.5px 8px 13.5px !important;
      line-height: 1;
      &:first-child {
        padding-left: 32px !important;
      }
      &:last-child {
        padding-right: 32px !important;
      }
    }
  }
  .table-responsive {
    display: inline-table;
  }
}

.leaderboard-touch-table {
  tbody tr {
    td {
      &:first-child {
        padding-left: 32px !important;
      }
    }
  }
  thead tr {
    th {
      padding: 16px 13.5px 8px 13.5px !important;
      line-height: 1;
      &:first-child {
        padding-left: 32px !important;
      }
      &:last-child {
        padding-right: 32px !important;
      }
    }
  }
  .pr-5 {
    padding-right: 14px !important;
  }
}

.table-top-footer-padding {
  padding: 16px 32px;
}

.table-footer-padding {
  padding: 11.5px 32px;
}
