.table-status-td-content {
  position: relative;
  .status-bar {
    display: flex;
    position: relative;
    .status-bar-single {
      position: relative;
      display: inline-flex;
      height: 20px;
      width: 8px;
      .status-bar-span {
        position: absolute;
        display: inline-block;
        height: 20px;
        width: 4px;
        background-color: #666666;
        border-radius: 2px;
        border: none;
        &:first-child.half {
          top: 0;
          left: 0;
          border-radius: 2px 2px 0 0;
        }
        &:last-child.half {
          top: 11px;
          left: 0;
          border-radius: 0 0 2px 2px;
        }
        &.half {
          height: 9px;
        }
        &.filled {
          background-color: $blue;
          &.fill-orange {
            background-color: $orange;
          }
          &.fill-purple {
            background-color: $purple;
          }
          &.fill-red {
            background-color: $red;
          }
          &.fill-green {
            background-color: $green;
          }
          &.fill-yellow {
            background-color: $yellow2;
          }
        }
      }
    }
  }
  .status-popup {
    position: absolute;
    width: 300px;
    // height: 280px;
    left: -300px;
    padding: 15px 25px;
    border: 1px solid #363636;
    border-radius: 8px;
    background-color: $disable-color;
    z-index: 1;
  }
  .status-popup-title {
    margin-bottom: 8px;
    .status-popup-title-span {
      margin-right: 22px;

      font-size: 12px;
      font-weight: $bold;
      color: #666666;
      &.selected {
        color: white;
      }
    }
  }
  .status-popup-body {
    padding-top: 5px;
    // border-top: 1px solid #363636;
    .status-popup-line {
      display: flex;
      justify-content: space-between;
      padding: 2px 0;
      .popup-line-step {
        display: inline-flex;
        line-height: 16px;
        .popup-line-text {
          font-size: 12px;
          color: $cream-50;
          &.orange {
            color: $orange;
          }
          &.purple {
            color: $purple;
          }
          &.red {
            color: $red;
          }
          &.green {
            color: $green;
          }
          &.yellow {
            color: $yellow2;
          }
        }
        .popup-line-subtext {
          align-items: center;
          font-size: 8px;
          border: 1px solid #363636;
          border-radius: 4px;
          height: 17px;
          padding: 0 8px;
          margin-left: 8px;
          color: $white;
          &.red {
            color: $red;
          }
        }
      }
      .popup-line-check {
        font-size: 12px;
        color: $cream-50;
        &.checked {
          color: $blue;
          &.orange i {
            color: $orange;
          }
          &.purple i {
            color: $purple;
          }
          &.red i {
            color: $red;
          }
          &.green i {
            color: $green;
          }
          &.yellow i {
            color: $yellow2;
          }
        }
      }
    }
  }
  .status-bar {
    display: flex;
    position: relative;
    .status-bar-item {
      position: relative;
      width: 72px;
      height: 24px;
      border-radius: 4px;
      font-size: 20px;
      line-height: 20px;
      font-weight: $bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .sbi-text {
        transform: scale(0.5);
        transform-origin: center;
        color: #e9e9e9;
      }
      .sbi-border {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
      }
    }
    .status-bar-draft {
      background-color: rgba(233, 233, 233, 0.16);
    }
    .status-bar-signing-up {
      background-color: rgba(245, 151, 98, 0.16);
      .sbi-border {
        background-color: #f59762;
      }
    }
    .status-bar-onboarding {
      background-color: rgba(0, 141, 255, 0.16);
      .sbi-border {
        background-color: #008dff;
      }
    }
    .status-bar-live {
      background-color: rgba(3, 201, 169, 0.16);
      .sbi-border {
        background-color: #03c9a9;
      }
    }
    .status-bar-canceling {
      background-color: rgba(255, 226, 0, 0.16);
      .sbi-border {
        background-color: #ffe200;
      }
    }
    .status-bar-canceled {
      background-color: rgba(237, 95, 101, 0.16);
      .sbi-border {
        background-color: #ed5f65;
      }
    }
  }
}

.table {
  tbody tr {
    .table-status-td-content {
      .status-popup {
        top: -258px;
        &.onboard {
          top: -258px;
        }
      }
    }
  }
  tbody tr:nth-child(-n + 8) {
    .table-status-td-content {
      .status-popup {
        top: -4px;
      }
    }
  }
}

.essentials-td {
  .essentials-td-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    i {
      font-size: 12px;
      color: $cream-25;
    }
    &.active {
      i {
        color: $cream-100;
      }
    }
  }
}

.support-container {
  width: 1440px;

  margin: auto;
  position: relative;
  padding: 25px 40px 100px 40px;
  height: 100%;
  min-height: 100vh;

  .support-header {
    display: flex;
    height: 120px;
    justify-content: space-between;
    position: relative;
    .support-header-leader {
      position: relative;
      width: 270px;
      .goback {
        top: 30px;
      }
    }
    .support-header-center {
      .support-title {
        position: relative;
        margin-bottom: 10px;
        .location-name {
          margin-bottom: 10px;
          max-width: 800px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .location-info-row {
          justify-content: center;
          display: flex;
          width: 100%;
          .location-info-tab {
            height: auto;
            padding: 0 5px;
            display: inline-block;
            .location-info-content {
              height: 36px;
              line-height: 36px;
              width: auto;
              display: inline-flex;
              border-radius: 8px;
              background-color: $disable-color;
              padding: 0 15px;
              align-items: center;
              .select-form-primary-dot {
                margin-right: 10px;
                margin-top: auto;
                margin-bottom: auto;
              }
              i {
                line-height: 36px;
                margin-right: 10px;
                color: $cream-50;
              }
              span {
                color: $cream-100;
                max-width: 123px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .support-header-trail {
      position: relative;
      width: 270px;
      display: flex;
      justify-content: flex-end;
      .request-access-area {
        display: inline;
        position: relative;
        height: fit-content;
      }
      .btn {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
      .access-modal {
        position: absolute;
        padding: 32px 24px;
        width: 290px;
        border: 1px solid #363636;
        border-radius: 8px;
        background-color: $disable-color;
        left: -290px;
        top: 0;
        .form-control {
          margin-bottom: 15px;
        }
      }
      .access-modal-backdrop {
        position: relative;
      }
    }
  }

  .support-body {
    height: calc(100% - 145px);
    // min-height: calc(100vh - 170px);
    position: relative;
    .support-sidebar {
      display: block;
      float: left;
      width: 200px;
      height: 100%;
      // min-height: calc(100vh - 170px);
      padding-right: 40px;
      .support-cancel {
        position: absolute;
        left: 0;
        bottom: 0;
        .support-cancel-btn {
          width: 160px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 2px solid rgba(233, 233, 233, 0.25);
          color: #ed5f65;
          font-size: 14px;
          font-weight: $bold;
          cursor: pointer;
        }
        .support-canceled-btn {
          width: 160px;
          height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 29px;
          border-radius: 8px;
          border: 2px solid rgba(233, 233, 233, 0.25);
          color: #ed5f65;
          font-size: 14px;
          font-weight: $bold;
          text-align: center;
          cursor: pointer;
        }
      }
      .support-siderbar-list {
        .btn-in-form {
          width: 160px;
          height: 36px;
          margin-bottom: 16px;
          border-radius: 8px;
          color: $cream-100;
        }
      }
    }
    .support-content {
      display: flex;
      border: 1px solid #363636;
      border-radius: 8px;
      padding: 30px 0 60px 0;
      justify-content: center;
      .phone-form {
        max-width: 305px;
      }
      .signup_form {
        .form-control {
          padding: 6px 16px;
        }
      }
      .form-control {
        padding: 0 15px;
      }
      .basic-info {
        height: 100%;
        width: 100%;
        max-width: 890px;
        .basic-info-row {
          display: flex;
          margin-bottom: 30px;
          .basic-info-section {
            display: inline-block;
            margin: 0 20px;
            width: 100%;
            max-width: 410px;
            &:first-child {
              margin-left: 0px;
            }
            &:last-child {
              margin-right: 0px;
            }
            &.small {
              padding-top: 3px;
              max-width: 200px;
            }
          }
        }
      }
    }
  }
  .support-menu-work {
    width: 500px;
    margin: 0 auto;

    .menu-work-two-btns {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
      .btn {
        margin: 0;
        width: 85px;
        display: inline-block;
        color: $cream-50;
        font-size: 14px;
        font-weight: $bold;
        &.selected {
          color: $cream-100;
        }
      }
    }

    .support-upload {
      width: 100%;
      padding: 30px 0 30px 90px;
      position: relative;
      .support-upload-left {
        height: 100%;
        display: inline-block;
        margin: auto;
        vertical-align: top;
        position: absolute;
        left: 0;
        .head {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          display: inline-block;
          background-color: #666666;
          justify-content: center;
          text-align: center;
          line-height: 60px;
          font-size: 23px;
          font-weight: $bold;
        }
      }
      .support-upload-right {
        height: 100%;
        .modifiers-title {
          height: 36px;
        }
        .inactive {
          background-color: $disable-color;
          pointer-events: none;
        }
        .form-control {
          width: 100%;
          height: 36px;
        }
        .upload-openhours {
          padding: 5px 0;
        }
      }
    }
  }
}

.log-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  min-height: 56px;
  z-index: 1;
  .log-span {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 180px;
    height: 100%;
    min-height: 56px;
    border: 1px solid #363636;
    border-radius: 8px;
    z-index: 1;
    background-color: #000016;
    &.large {
      width: 272px;
    }
    .log-leader {
      display: inline-block;
      .log-title {
        font-size: 10px;
      }
      .log-info {
        max-width: 200px;
        font-size: 14px;
      }
    }
    .log-trail {
      font-size: 10px;
      font-weight: $bold;
      color: $cream-25;
    }
  }
}

.center-back-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #363636;
  top: 50%;
  z-index: 0;
}

.log-header {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  i {
    font-size: 24px;
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
  }
}

.userAvatar {
  display: flex;
  align-items: center;
  &_name {
    margin-left: 6px;
  }
}
.plan-card {
  width: 110px;
  height: 36px;
  border: 1px solid #707070;
  border-radius: 8px;
  background-color: $disable-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .select-form-primary-dot {
    margin-right: 10px;
  }
  .name {
    line-height: 36px;
  }
}
.support-orders {
  margin-bottom: -60px;
}

.support-orders-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  .support-order-filter-control {
    width: 200px;
    .date-range-control {
      height: 36px;
    }
  }
}
.support-orders-table {
  padding: 0 -10px;

  &.primary-table .table-hover tbody tr:hover td {
    border-radius: 0;
  }
  tbody {
    td {
      border-radius: 0;
      white-space: nowrap;
    }
  }
}

.support-od-table-pagination {
  padding: 20px 0;
}

.supprt-order-detail-card {
  position: absolute;
  left: 0;
  top: 85px;
  width: calc(100% + 80px);
  height: calc(100vh - 85px);
  margin: 0 -40px;
  display: flex;
  color: $cream-75;
  z-index: 3;
  overflow: auto;
  .board-content {
    height: auto;
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .od-header {
    margin: 60px auto 40px auto;
  }
  .od-side {
    width: 260px;
    display: inline-block;
    .od-side-confirm {
      padding: 10px 30px;
      .form-control {
        height: 36px;
        margin-bottom: 10px;
      }
    }
  }
  .od-outlined-section {
    padding: 10px 0px;
    border: 1px solid #363636;
    border-radius: 8px;
  }
  .od-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 30px;
    .od-row-left {
      color: $cream-50;
      font-size: 12px;
      font-weight: $bold;
    }
    .od-row-right {
      font-size: 14px;
      &.green {
        color: $green;
      }
    }
  }
  .od-content {
    width: 600px;
    display: inline-block;
  }

  .od-items {
    padding-top: 30px;
  }

  .od-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    &:last-child {
      border-bottom: 1px solid #363636;
    }
    .customer-note {
      width: 100%;
      padding: 10px 0;
      font-size: 14px;
    }
  }

  .odi-header {
    width: 35px;
  }

  .odi-qty {
    display: inline-block;
    text-align: center;
    margin: 1px 0;
    font-size: 16px;
    line-height: 24px;
    &.warning {
      color: $yellow;
    }
  }

  .odi-body {
    width: calc(100% - 60px - 120px);

    .name {
      font-size: 16px;
    }
    .category-name {
      font-weight: $bold;
      font-size: 12px;
      color: $cream-50;
    }

    .odi-option {
      border-left: 1px solid $cream-50;
      margin: 10px 0;
      padding: 0 10px;
      .odi-option-name {
        font-size: 14px;
      }
      .odi-option-val {
        font-size: 14px;
      }
    }

    .location-note {
      font-weight: $semi-bold;
    }
  }
  .od-customer-notes {
    padding: 15px 0;
    .od-notes-title {
      font-weight: $bold;
      font-size: 16px;
    }
  }
  .odi-footer {
    width: 120px;
    text-align: right;

    .item-price {
      font-size: 16px;
    }
    .opp-original {
      text-decoration: line-through;
      margin-right: 6px;
    }
  }

  .od-summary {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 0;
    border-top: 1px solid $separator-color;
  }

  .ods-header {
    width: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .odsh-qty {
      font-size: 16px;
    }

    .odsh-curbside {
      span {
        font-weight: $semi-bold;
      }
    }
    .color {
      .White {
        &::before {
          font-weight: 300;
          color: #eaeaea;
          content: '\f0c8';
        }
      }
      .Black {
        color: #000;
      }
      .Silver {
        color: #d9d9d9;
      }
      .Blue {
        color: #001ec3;
      }
      .Red {
        color: #e51616;
      }
      .Brown {
        color: #724212;
      }
      .Green {
        color: #499f00;
      }
      .Other {
        &::before {
          font-weight: 300;
          color: #eaeaea;
          content: '\f2fd';
        }
      }
    }

    .odsh-actions {
      display: flex;

      .btn {
        + .btn {
          margin-left: 0.5rem;
        }
      }
      .btn-outline-danger {
        border-color: #eaeaea;
        font-size: 12px;
        width: 130px;
        &:hover {
          border-color: $danger;
        }
      }
    }
  }

  .ods-footer {
    width: 300px;

    table {
      width: 100%;

      td {
        &:last-child {
          text-align: right;
        }
        font-size: 16px;
      }

      tr {
        padding: 4px 0;
        &:last-child {
          td {
            color: white;
            font-weight: $bold;
          }
        }
      }
    }
  }
}

.support-onboarding {
  width: 860px;
  .board-equal-row {
    margin: 0 -20px;
    .board-col-primary {
      padding: 0 20px;
      height: auto;
    }
  }
  .form-control {
    height: 36px;
    &:focus {
      background-color: transparent;
    }
  }
  .account-user {
    .account-user-title {
      font-size: 12px;
      font-weight: bold;
      color: #747480;
      padding-bottom: 8px;
    }
    .account-user-row,
    .account-user-contaniar {
      .table-1 {
        width: 105px;
      }
      .table-2 {
        width: 120px;
      }
      .table-3 {
        width: 145px;
      }
      .table-4 {
        width: 220px;
      }
      .table-5 {
        width: 200px;
      }
    }
    .account-user-row {
      border-top: 1px dashed #363636;
      border-bottom: 1px dashed #363636;
      padding: 8px;
      .user-row-table {
        display: inline-block;
        font-size: 10px;
        color: #e9e9e980;
      }
    }
    .account-user-contaniar {
      border-bottom: 1px dashed #363636;
      padding-top: 16px;
      .user-contaniar-col {
        display: flex;
        flex-direction: row;
        padding: 0 8px 16px 8px;
        width: 100%;
        .col-table-text {
          padding-bottom: 16px;
        }
      }
    }
  }
  .onboarding-preference {
    .op-title {
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      color: #747480;
      padding-top: 32px;
      padding-bottom: 8px;
    }
    .op-row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .op-icon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: #121217;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: rgba(233, 233, 233, 0.5);
        }
      }
      .op-option {
        width: 219px;
        .op-option-input {
          padding: 0 10px;
        }
        .op-option-button {
          width: 198px;
          height: 36px;
          padding: 10px 16px;
          background-color: #121217;
          border-radius: 8px;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .mr-8 {
        margin-right: 8px;
      }
    }
  }
  .onboarding-flex {
    display: flex;
    .progress-check-list {
      flex: 1;
      padding-right: 32px;
      .pcl-title {
        font-size: 12px;
        color: #747480;
        font-weight: bold;
        line-height: 14px;
      }
      .pcl-select {
        width: 400px;
        margin-top: 8px;
        .form-row {
          margin: 0;
          margin-bottom: 8px;
          .col {
            padding: 0;
          }
          .button-left {
            margin-right: 8px;
          }
        }
        .pcl-row {
          display: flex;
          margin-bottom: 8px;
          .pcl-button {
            height: 36px;
            border-radius: 8px;
            background-color: #121217;
            display: flex;
            align-items: center;
            padding: 0 16px;
            flex: 1;
            cursor: pointer;
          }
          .pcl-img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }
          .pcl-icon {
            width: 36px;
            flex: none;
            padding: 0;
            justify-content: center;
            i {
              color: rgba(233, 233, 233, 0.25);
            }
          }
          .action {
            i {
              color: #03c9a9;
            }
          }
          .pcl-input {
            width: 222px;
            margin-right: 8px;
          }
          .mr-8 {
            margin-right: 8px;
          }
        }
        .pcl-submit {
          margin-top: 32px;
          display: flex;
          justify-content: center;
          .pcl-submit-button {
            width: 256px;
            height: 51px;
            color: #e9e9e9;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
    .progress-notes {
      flex: 1;
      padding-left: 32px;
      .pn-title {
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: #747480;
        padding-bottom: 8px;
      }
      .pn-import {
        display: flex;
        margin-bottom: 24px;
        .pn-import-input {
          flex: 1;
          resize: none;
          height: 52px;
          padding: 10px 16px;
        }
        .pn-import-submit {
          width: 36px;
          height: 52px;
          border-radius: 8px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          i {
            color: #000;
            font-size: 18px;
          }
        }
      }
      .pn-overflow {
        .progress-review {
          margin-bottom: 24px;
          .pr-close {
            display: flex;
            align-items: center;
            .pr-close-title {
              width: 369px;
              flex: 1;
              height: 36px;
              border-radius: 8px;
              background-color: #121217;
              padding: 10px 16px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              color: rgba(233, 233, 233, 0.5);
              text-decoration-line: line-through;
            }
            .pr-close-icon {
              margin-left: 9.25px;
              width: 17.5px;
              height: 17.5px;
              text-align: right;
              i {
                font-size: 17.5px;
                color: #e9e9e9;
              }
            }
          }
          .pr-container {
            .pr-container-header {
              height: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;
              .pr-header-left {
                font-size: 14px;
                line-height: 16.4px;
                color: #e9e9e9;
                display: flex;
                align-items: center;
                .header-left-icon {
                  margin-right: 10px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  overflow: hidden;
                  font-size: 20px;
                }
                .header-left-img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 10px;
                }
              }
              .pr-header-right {
                .header-right-time {
                  font-size: 14px;
                  line-height: 16.4px;
                  color: rgba(233, 233, 233, 0.5);
                }
                .header-right-icon {
                  padding-left: 15px;
                  i {
                    cursor: pointer;
                    font-size: 15px;
                    color: rgba(233, 233, 233, 0.25);
                  }
                }
              }
            }
            .pr-container-info {
              .pr-info-text {
                border-radius: 8px;
                padding: 10px 16px;
                font-size: 14px;
                color: #e9e9e9;
                background-color: #121217;
                word-break: break-word;
              }
              .pr-info {
                display: flex;
                .pr-info-input {
                  flex: 1;
                  resize: none;
                  height: 68px;
                  padding: 10px 16px;
                }
                .pr-info-button {
                  margin-left: 8px;
                  width: 36px;
                  height: 68px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #e9e9e9;
                  border-radius: 8px;
                  i {
                    color: #000016;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dialog-btn {
    width: 100%;
    height: 100%;
    border: 2px solid #e9e9e9;
    border-radius: 8px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .dialog-btn-text {
      font-size: 14px;
      font-weight: bold;
      color: #e9e9e9;
    }
    .dialog-btn-icon {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      background-color: rgba(233, 233, 233, 0.25);
    }
    .is-blank {
      background-color: #03c9a9;
    }
  }
}

.suopport-onboarding-notes {
  height: 138px;
  &.in-modal {
    height: 180px;
    .write-btn {
      margin-left: 10px;
    }
    .form-control:disabled {
      background-color: #121217;
    }
    .form-control:focus {
      background-color: #000016;
    }
  }
  .form-row {
    height: 100%;
    .form-group {
      height: 100%;
      .form-control {
        height: 100%;
        padding: 8px 15px;
        resize: none;
      }
    }
  }
}

.support-onboarding-seperate-line {
  width: 100%;
  margin: 30px 0;
}

.support-onboarding-date-control {
  display: flex;
  justify-content: center;
}
.onboarding-date-picker {
  display: inline-block;
  width: 200px;
  margin-left: 10px;
}

.msd-model-header {
  margin-bottom: 24px !important;
  .modal-title {
    font-size: 14px;
    color: rgba(233, 233, 233, 0.75);
    font-weight: 700;
  }
}

.go-back-btn {
  width: 107px;
  height: 40px;
  border-radius: 8px;
  border: 2px solid rgba(233, 233, 233, 0.25);
  font-size: 14px;
  color: $cream-100;
  font-weight: $bold;
}
