.map-view-search {
  border: 1px solid #363636;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  .input-wrap {
    width: 360px;
    margin-top: 216px;
    .tips {
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
      color: rgba(233, 233, 233, 0.75);
    }
  }
}
