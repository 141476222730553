.brandSearchSelect {
  position: relative;
  width: 100%;
  border: 1px solid $field-color;
  background-color: $field-color;
  border-radius: 8px;
  z-index: 1;
  .form-control {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
    &:disabled {
      border-color: #121217;
    }
  }
  .exclamation {
    left: -14px;
    top: 12px;
  }
  &.isOpen {
    .form-control {
      background-color: $black;
      border-color: $black;
    }
    border-color: #fff;
    background-color: $black;
  }
  .brandSearchDropdownItem {
    color: $cream-100;
    text-align: left;
    white-space: pre-wrap;
    padding: 0.5rem 1rem;
    font-size: 14px;
    width: 100%;
    .fa-store {
      color: $cream-50;
    }
    &:hover {
      background-color: $field-color;
    }
    &:last-child {
      margin-bottom: 5px;
    }
    &.divider {
      border-top: 1px solid $field-color;
      width: calc(100% - 2rem);
      margin: auto;
      padding: 0;
    }
    &.creatable {
      color: $yellow2;
      font-weight: $bold;
    }
    .brandSearchDropdownLocation {
      padding: 0 4px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid rgba(233, 233, 233, 0.25);
      margin-left: 8px;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        color: rgba(233, 233, 233, 0.75);
        transform: scale(0.83);
        transform-origin: center;
      }
    }
  }
  .brandSearchDropdown {
    padding-top: 0.25rem;
  }
}
