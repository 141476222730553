.btn-primary {
  color: $white;
  background-image: linear-gradient(#209bff, #1377c0);
  border: none;
  border-radius: 30px !important;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    background-image: linear-gradient(#4ed38f, #00b6aa);
    box-shadow: none;
    border: none;
    background-color: $green;
  }
  &:not(:disabled):not(.disabled):hover {
    transition: background-image 0.2s ease-in-out;
  }
  &:disabled {
    background-image: linear-gradient(#dddddd, #aaaaaa);
  }
}

.btn-primary-login,
.btn-primary-onboarding {
  height: 61px;
  width: 320px;
  max-width: 100%;
  font-size: 18px;
  font-weight: bold;
  margin: 30px auto 0 auto;
  display: block;
}

.approval-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  .approval-button-tips {
    margin-top: 50px;
    width: 256px;
    height: 54px;
    border-radius: 8px;
    background: rgba(245, 151, 98, 0.1);
    padding: 10px 16px;
    color: #f59762;
    font-size: 14px;
    line-height: 16.8px;
    text-align: center;
  }
  .approval-button-btn {
    margin-top: 16px;
    .btn-primary-approval {
      height: 61px;
      width: 320px;
      max-width: 100%;
      font-size: 18px;
      font-weight: bold;
      display: block;
      background: linear-gradient(180deg, #f59762 0%, #ff8743 100%);
    }
  }
}

.btn-light {
  color: $black;
  background-color: #fff;
  background-image: linear-gradient(white, $cream-100);
  border-color: white;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    border: none;
    background-color: #fff;
    background-image: linear-gradient(white, $cream-100);
  }
}

.btn-lg {
  height: 61px;
  font-size: 18px;
  font-weight: bold;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-primary-upload {
  height: 61px;
  font-size: 18px;
  font-weight: bold;
}

.btn-secondary {
  border: 1px solid $white;
  border-radius: 18px;
  font-size: 14px;
  font-weight: $regular;
  height: 34px;
  background-color: transparent;
  box-shadow: none !important;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    border-color: $green;
    color: $green;
    background-color: transparent;
  }
}

.btn-menu {
  color: $black;
  border: 1px solid $white;
  border-radius: 8px;
  font-size: 14px;
  height: 34px;
  background-color: $cream-100;
  box-shadow: none !important;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active {
    border-color: $green;
    color: $green;
    background-color: transparent;
  }
}

.btn-link {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    box-shadow: none;
  }
}
.btn-board-red-link {
  color: $red;
  &:hover,
  &:focus,
  &:active {
    color: $red;
    text-decoration: none;
    box-shadow: none;
  }
}

.btn-profile {
  height: 32px !important;
  min-width: 135px;
  border-radius: 16px;
  position: relative;
  border: none !important;
  background-color: $field-color;
  color: $white;
  padding-right: 10px;
  margin: 0 40px 0 0;
  font-size: 14px;
  font-weight: $regular;
  .head-sculpture {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    transform: translate(-10px, 0);
    float: left;
    i {
      color: #666666;
    }
  }
  .name-profile {
    margin: auto 10px auto 0;
  }
}

.btn-board-close {
  position: absolute;
  right: 40px;
  top: 30px;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 22px;
  background-color: $field-color;

  i {
    color: $white;
  }
}

.btn-white-block,
.btn-thirdary {
  width: 91px;
  height: 36px;
  background: $cream-100;
  margin: auto;
  border-radius: 8px;
  font-size: 14px;
  padding: 0px;
  font-weight: $regular;
  box-shadow: none;
  line-height: 1;
  &:hover {
    box-shadow: none;
    opacity: 0.8;
  }
}

.btn-white-block {
  position: relative;
  .copy-success-alert {
    position: absolute;
    top: -34px;
    left: 50%;
    color: $green;
    transform: translateX(-50%);
    background-color: $black;
    border-radius: 6px;
    padding: 6px 10px;
    font-size: 12px;
    margin: 0;
  }
  &.support-header-btn {
    font-size: 20px;
    border-radius: 18px;
    width: auto;
  }
}

.two-btns-row {
  margin-top: 10px;
  padding: -5px;
  justify-content: space-between;
  display: flex;
  .two-btns-btn {
    width: calc(50% - 5px);
    padding: 0;
  }
}

.btn-in-form {
  background-color: #292a33;
  border-color: #292a33;
  color: $white;
  border-radius: 8px;
  box-shadow: none;
  font-size: 14px;
  &.select,
  &:active,
  &:focus {
    background-color: $disable-color;
    border-color: $white;
    color: $white;
  }
  &:hover {
    background-color: #292a33;
    border-color: $white;
    color: $white;
    box-shadow: none;
  }
  &.disable {
    pointer-events: none;
  }
}

.check-area {
  padding: 5px;
}
.check-btn {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  border: none;
  background-color: #292a33;
  color: $green;
  &:hover,
  &:active,
  &:focus {
    background-color: #292a33 !important;
  }
  &:disabled {
    background-color: $disable-color !important;
    color: $green;
    opacity: 1;
  }
}

.write-btn {
  width: 36px;
  height: 100%;
  i {
    color: black;
    font-size: 14px;
    width: 14px;
  }
}
.btn-inform {
  height: 36px;
  width: 100%;
  .i-front {
    font-size: 15px;
    margin-right: 10px;
  }
  .i-trail {
    float: right;
    margin-right: 10px;
  }
}

.btn-outline {
  border: 1px solid #363636;
  border-radius: 8px;
  &.danger {
    color: $red;
  }
}

.btn-outline-white {
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  padding: 9px 24px !important;
  &:hover {
    color: white;
  }
}

.red-color-gray-border {
  color: $red;
  border: 1px solid #363636;
  border-radius: 10px;
  font-size: 14px;
  min-width: 95px;
}

.bg-white-black-color {
  color: $black;
  background-color: $cream-100;
  font-size: 14px;
  min-width: 95px;
  border-radius: 10px;
}
