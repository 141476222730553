.board-card {
  .btn-board-close {
    left: 40px;
  }
  .user-name {
    margin-top: 21px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #e9e9e9;
  }
  .profile-wrap {
    width: 738px;
    padding: 20px 120px;
    margin-top: 32px;
    border: 1px solid #363636;
    border-radius: 16px;
    // 头像预览
    .upload-preview {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    // 表单样式重置
    .form-group {
      line-height: 1;
      label {
        margin-bottom: 8px;
      }
    }
    .form-row {
      margin-top: 24px;
      &:first-child {
        margin-top: 32px;
      }
    }
    .form-row > .col {
      padding: 0 5px;
      margin: 0;
    }
    .form-row > [class*='col-'] {
      padding: 0 5px;
      margin: 0;
    }
    .profile-email-signature {
      a {
        color: #222;
      }
    }
    .copy-wrap {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 16px;
      .copy-btn {
        width: 240px;
        height: 40px;
        background: #e9e9e9;
        border: 2px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 8px;
        color: #000016;
        cursor: pointer;
        font-weight: bold;
      }
    }

    .cross-line {
      border: 1px dashed #363636;
      margin: 24px 0;
    }
  }
  .update-profile {
    margin-top: 32px;
    text-align: center;
    button {
      width: 256px;
      height: 51px;
      line-height: 51px;
      padding: 0;
      color: #e9e9e9;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
