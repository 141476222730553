.address-input-select {
  position: relative;
  width: 100%;
  background-color: $field-color;
  border-radius: 8px;
  .form-control {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  .exclamation {
    left: -14px;
    top: 12px;
  }
  &.isOpen {
    .form-control {
      background-color: $black;
      border-color: $black;
    }
    border: 1px solid #fff;
    background-color: $black;
  }
  .address-spanture {
    font-size: 10px;
    font-weight: $semi-bold;
    color: $cream-50;
    padding-left: 12px;
    padding-right: 12px;
  }
  .option-button {
    color: #fff;
    text-align: left;
    white-space: pre-wrap;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    width: 100%;
    &:hover {
      background-color: $field-color;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
  .address-suggestions {
    padding: 14px 0;
  }
}
