body {
  font-family: 'Roboto', sans-serif;
}

.auth-layout {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 145px;
  background-color: $black;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  color: $white;
  > * {
    background-color: $black;
    color: $white;
  }
}

.main-layout {
  // width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: $black;
  font-size: 14px;
  font-weight: $regular;
  > * {
    background-color: $black;
    color: $white;
    font-size: 14px;
    font-weight: $regular;
  }
  .main-nav {
    padding: 20px 40px 10px 40px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    .navbar {
      padding: 0;
      .nav-item {
        margin-right: 32px;

        a:hover {
          text-decoration: none;
        }
      }
    }
  }
  .main-content {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 86px);
    padding: 0;
    .onboardings-content, 
    .liveorder-page, 
    .leaderboard-page, 
    .feature-container {
      padding: 0 40px 70px 40px;
    }
  }
}

.detail-layout {
  position: relative;
  overflow: hidden;
  .detail-leftside {
    width: calc(100vw - 320px);
    min-height: 100vh;

    display: inline-block;
    .container-with-rightside {
      max-width: 1040px;
      height: 100%;
      padding: 0;
      margin: auto;
      .detail-header {
        margin: 25px 40px 20px 40px;
        position: relative;
        height: 43px;
        .goback {
          position: absolute;
          margin: auto 0;
          top: calc(50% - 18px);
        }
      }
      .detail-content {
        padding: 0 40px 70px 40px;
      }
    }
  }

  .detail-rightside {
    width: 320px;
    height: 100%;
    position: absolute;
    min-height: 100vh;
    padding: 30px;
    display: inline-block;
    background-color: $card-color;
    overflow: auto;
    .account-card {
      padding: 15px;
      margin-top: 15px;
      border-radius: 8px;
      background-color: $disable-color;
      position: relative;
      .account-head {
        width: 28px;
        height: 100%;
        display: inline-block;
        i {
          color: #666666;
          position: absolute;
          top: 15px;
        }
        .profile-photo {
          position: absolute;
          top: 15px;
          width: 28px;
          height: 28px;
          border-radius: 14px;
        }
      }
      .account-user-select {
        margin-top: 10px;
      }
      .account-info {
        width: 200px;
        padding: 0 10px;
        display: inline-block;
      }
    }
  }
  .right-side-content {
    .right-side-content-section {
      margin-bottom: 55px;
      .info-row {
        padding: 5px 0;
      }
      .info-bar {
        height: 36px;
        margin: 0;
        padding: 0 15px;
        border-radius: 8px;
        background-color: $disable-color;
        display: flex;
        justify-content: space-between;
        .num-bold {
          text-align: center;
          font-size: 28px;
          font-weight: $bold;
          color: $white;
          line-height: 36px;
        }
        .train {
          justify-content: center;
          display: flex;
          align-items: center;
        }
        &.height-72 {
          height: 72px;
        }
        &.height-67 {
          height: 67px;
        }
      }
      .btn-claim {
        margin: 5px 0;
      }
    }
  }
}
