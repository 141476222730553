.modal.show .modal-dialog {
  transform: none !important;
}

.modal.fade-right {
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(100%, 0);
    .modal-content {
      position: absolute;
      right: 0;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.modal.fade-left {
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(-100%, 0);
    .modal-content {
      position: absolute;
      left: 0;
      top: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.modal-dialog {
  &.user-update-modal {
    margin-top: 50px;
  }
  &.date-range-modal {
    .modal-content {
      width: fit-content;
      background-color: #292a33;
      .modal-body {
        .rdrDefinedRangesWrapper {
          background-color: #292a33;
          color: $white;
        }
        .rdrStaticRange {
          background-color: #292a33;
          color: $white;
          &:hover,
          &:focus,
          &:active {
            background-color: $gray;
            color: $gray;
          }
        }
        .rdrCalendarWrapper {
          background-color: #292a33;
          .rdrDateDisplayWrapper {
            background-color: #292a33;
            .rdrDateDisplay {
              .rdrDateInput {
                background-color: #292a33;
                color: white;
              }
              .rdrDateDisplayItemActive,
              .rdrDateDisplayItem {
                input {
                  color: white;
                }
              }
            }
          }
        }
        .rdrMonthAndYearPickers {
          .rdrMonthPicker,
          .rdrYearPicker {
            select {
              color: white;
              option {
                color: black;
              }
            }
          }
        }
        .rdrDays {
          .rdrDayDisabled {
            background-color: #1f2027;
            .rdrDayNumber {
              span {
                color: $cream-50;
              }
            }
          }
          .rdrDayToday {
          }
        }
        .rdrDayPassive {
          .rdrDayNumber {
            span {
              color: $cream-25;
            }
          }
        }
        .rdrDayNumber {
          span {
            color: $cream-100;
          }
        }
        .rdrInputRangeInput {
          color: white;
        }
      }
    }
  }
  &.support-cancel-modal {
    width: 460px;
    .modal-content {
      width: 460px;
      padding: 0;
    }
    .scm-container {
      width: 100%;
      padding: 24px 32px;
      border-radius: 16px;
      background-color: #1C1C21;
      border: 1px solid #363636;
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.32);
      .scm-title {
        text-align: center;
        color: $cream-75;
        font-weight: $bold;
        font-size: 14px;
        padding-bottom: 24px;
      }
      .scm-reason {
        .scm-reason-title {
          color: $cream-50;
          font-weight: $bold;
          font-size: 12px;
          padding-bottom: 8px;
        }
        .scm-reason-row {
          display: flex;
          justify-content: space-between;
          .reason-row-select {
            width: 192px;
            height: 36px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            background-color: #292A33;
            border-radius: 8px;
            margin-bottom: 8px;
            cursor: pointer;
          }
          .reason-row-action {
            background-color: #000016;
          }
        }
      }
      .scm-comment  {
        margin-bottom: 24px;
        .scm-comment-title {
          color: $cream-50;
          font-weight: $bold;
          font-size: 12px;
          padding-bottom: 8px;
          padding-top: 16px;
        }
        .scm-comment-input {
          height: 68px;
          padding: 10px 16px;
          resize: none;
        }
      }
      .scm-people {
        display: flex;
        justify-content: space-between;
        .scm-people-handled, .scm-people-appoved {
          .scm-people-title {
            color: $cream-50;
            font-weight: $bold;
            font-size: 12px;
            padding-bottom: 8px;
          }
          .scm-people-user {
            width: 192px;
            height: 36px;
          }
          .scm-people-name {
            width: 392px;
            height: 36px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            background-color: #292A33;
            border-radius: 8px;
            .people-name-img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;
            }
            i {
              font-size: 20px;
              padding-right: 10px;
            }
          }
        }
        .scm-people-appoved {
          .scm-people-name {
            background-color: #121217;
          }
        }
      }
      .scm-btn {
        margin-top: 24px;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed #363636;
        .scm-btn-back {
          width: 120px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 2px solid $cream-25;
          cursor: pointer;
          color: $cream-100;
          font-size: 14px;
          font-weight: $bold;
        }
        .scm-btn-confirm {
          width: 256px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 2px solid $cream-25;
          cursor: pointer;
          color: #ED5F65;
          font-size: 14px;
          font-weight: $bold;
        }
      }
      .scm-select {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        .scm-select-img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          font-size: 20px;
        }
        .scm-select-text {
          padding-left: 10px;
        }
      }
    }
  }
  &.support-cancel-submit {
    width: 384px;
    .modal-content {
      width: 384px;
      padding: 0;
    }
    .scm-submit {
      width: 100%;
      padding: 24px 32px;
      border-radius: 8px;
      background-color: #1C1C21;
      border: 1px solid #363636;
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.32);
      display: flex;
      flex-direction: column;
      align-items: center;
      .scm-submit-title {
        text-align: center;
        color: #ED5F65;
        font-weight: $bold;
        padding-bottom: 16px;
      }
      .scm-submit-text {
        text-align: center;
        color: $cream-100;
        padding-bottom: 24px;
        line-height: 1.2;
      }
      .scm-submit-btn {
        width: 144px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $cream-100;
        border-radius: 8px;
        color: #000016;
        font-weight: $bold;
        cursor: pointer;
      }
    }
  }
}

.modal-content {
  padding: 30px 30px;
  border-radius: 8px;
  width: 495px;
  background-color: $disable-color;
  color: $white;
  &.user-update-modal {
    width: 495px;
  }
}

.modal-header {
  border-bottom: 0px;
  background-color: $disable-color;
  justify-content: center;
  padding: 0;
  margin-bottom: 40px;
  text-align: center;
  .modal-title {
    font-size: 20px;
    font-weight: $semi-bold;
    width: 100%;
  }
  .close {
    color: $white;
  }
}

.modal-body {
  padding: 0;
  .lcontent {
    display: flex;
    justify-content: space-between;
  }
}

.modal-footer {
  border-top: 0px;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.5rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.5rem;
}

.warning-info {
  margin: 15px 0;
  padding: 10px 15px;
  width: 100%;
  min-height: 50px;
  font-size: 14px;
  font-weight: $regular;
}

.alert-modal {
  .head {
    font-size: 20px;
    font-weight: $bold;
    text-align: center;
  }
}

.domain-information-modal {
  .modal-content {
    padding: 40px 60px;
    background-color: #1c1c21;
    border-radius: 36px;
  }
  .modal-header {
    margin-bottom: 30px;
    background-color: #1c1c21;
  }
  .from-row {
    margin-bottom: 5px;
  }
}

.day-disabled {
  color: $cream-25 !important;
}

.ReactModal__Overlay {
  display: flex !important;
  justify-content: center !important;
  z-index: 200;
  background-color: transparent !important;
}

.ReactModal__Content {
  overflow: hidden;
  position: relative !important;
  max-width: 1300px;
  width: 100vw;
  inset: 0 !important;
  margin-top: 80px;
  background-color: #1c1c21 !important;
  border-radius: 40px 40px 0px 0px !important;
  .close-button {
    top: 31px;
    cursor: pointer;
    display: flex;
    justify-content: right;
    margin-right: 40px;
    z-index: 99;
  }
  .modal-content-container {
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: calc(100% - 10px);
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-y: scroll;
    height: 100%;
  }
}

.msd {
  .modal-content {
    padding: 0;
    box-shadow: 0px 16px 32px rgba (0,  0,  0,  0.32);
  }
  .msd-container {
    width: 492px;
    background-color: #1c1c21;
    border-radius: 16px;
    padding: 32px 64px;
    position: relative;
    z-index: 1;
    .msd-title {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 700;
      color: rgba(233, 233, 233, 0.75);
    }
    .msd-link {
      padding: 24px 0;
      display: flex;
      border-bottom: 1px dashed #363636;
      .msd-link-left {
        margin-right: 8px;
        .msd-link-text {
          width: 320px;
          height: 36px;
          background-color: #121217;
          border-radius: 8px;
          padding: 0 16px;
          display: flex;
          align-items: center;
        }
        .msd-link-input {
          width: 320px;
        }
      }
      .msd-link-icon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        background-color: #e9e9e9;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: #000016;
        }
      }
      .action {
        background-color: #008dff;
        i {
          color: #e9e9e9;
        }
      }
    }
    .msd-note-title {
      padding: 24px 0 8px 0;
      font-size: 12px;
      color: rgba(233, 233, 233, 0.5);
      font-weight: bold;
    }
    .msd-note {
      display: flex;
      .msd-note-left {
        margin-right: 8px;
        .msd-note-text {
          width: 320px;
          height: 68px;
          background-color: #121217;
          border-radius: 8px;
          padding: 10px 16px;
          overflow-y: auto;
        }
        .msd-note-input {
          width: 320px;
          height: 68px;
          resize: none;
          padding: 10px 16px;
        }
      }
      .msd-note-icon {
        width: 36px;
        height: 68px;
        border-radius: 8px;
        background-color: #e9e9e9;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: #000016;
        }
      }
      .action {
        background-color: #008dff;
        i {
          color: #e9e9e9;
        }
      }
    }
    .msd-info {
      display: flex;
      margin-top: 24px;
      .msd-info-left {
        margin-right: 8px;
        .msd-info-text {
          width: 320px;
          height: 112px;
          background-color: #121217;
          border-radius: 8px;
          padding: 10px 16px;
          overflow-y: auto;
        }
        .msd-info-input {
          width: 320px;
          height: 112px;
          resize: none;
          padding: 10px 16px;
        }
      }
      .msd-info-icon {
        width: 36px;
        height: 112px;
        border-radius: 8px;
        background-color: #e9e9e9;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: #000016;
        }
      }
      .action {
        background-color: #008dff;
        i {
          color: #e9e9e9;
        }
      }
    }
    .msd-upload {
      padding: 24px 0;
      border-bottom: 1px dashed #363636;
      .msd-upload-menus {
        margin-bottom: 18px;
        max-height: 150px;
        overflow-y: auto;
        .msd-upload-menus-row {
          display: flex;
          margin-bottom: 8px;
          .msd-upload-menus-text {
            width: 320px;
            height: 36px;
            padding: 0 16px;
            margin-right: 8px;
            background-color: #121217;
            border-radius: 8px;
          }
          .msd-upload-menus-icon {
            width: 36px;
            height: 36px;
            cursor: pointer;
            border-radius: 8px;
            background-color: #e9e9e9;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              color: #000016;
            }
          }
        }
      }
      .msd-upload-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .msd-upload-add {
          width: 98px;
          height: 16px;
          position: relative;
          cursor: pointer;
          .msd-upload-add-text {
            font-size: 14px;
            color: #e9e9e9;
            font-weight: bold;
          }
          .msd-upload-add-input {
            width: 95px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
          }
        }
        .msd-upload-all {
          width: 153px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: #000016;
          background-color: #e9e9e9;
          cursor: pointer;
          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
