.map-view-result {
  border: 1px solid #363636;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 16px;
  display: flex;
  height: 712px;
  // height: calc(100vh - 88px - 64px);
  .list-wrap {
    width: 320px;
    // height: 400px;
    .header-tips {
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      padding-left: 24px;
      background-color: #121217;
      color: rgba(233, 233, 233, 0.75);
    }
    .location-wrap {
      height: calc(100% - 30px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }

      & {
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
    .location-item {
      cursor: pointer;
      padding: 24px;
      background-color: #000016;
      &.isActive {
        background-color: #292a33;
      }
      &:nth-child(1) {
        border-top: 1px solid #363636;
      }
      border-bottom: 1px solid #363636;
      position: relative;
      .signup-mark {
        position: absolute;
        width: 4px;
        height: 36px;
        background-color: #008dff;
        border-radius: 2px;
        &.green {
          background-color: #03c9a9;
        }
      }
      .main-item {
        margin-left: 12px;
      }
      .item-name {
        font-family: Roboto;
        font-weight: bold;
        font-size: 16px;
        line-height: 1;
        color: #e9e9e9;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .name {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //用省略号显示
          white-space: nowrap; //不换行
          &.haveDay {
            max-width: 195px;
          }
        }
        .diff-day {
          position: relative;
          width: 45px;
          height: 16px;
          .tips {
            position: absolute;
            padding: 8px 16px;
            margin-left: 8px;
            font-size: 16px;
            transform: scale(0.5);
            transform-origin: left center;
            font-weight: lighter;
            line-height: 18px;
            color: rgba(233, 233, 233, 0.75);
            background: rgba(233, 233, 233, 0.25);
            border-radius: 8px;
            display: block;
            text-align: center;
            left: 0;
            top: -10px;
            &.big {
              width: 100px;
            }
            &.middle {
              width: 90px;
            }
            &.small {
              width: 80px;
            }
          }
        }
      }
      .info-wrap {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: rgba(233, 233, 233, 0.75);
        line-height: 1;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        .info {
          width: 116px;
        }
        .price {
          width: 80px;
        }
        .city {
          width: 80px;
        }
        i {
          margin-right: 4px;
          color: rgba(233, 233, 233, 0.5);
        }
      }
      .website {
        margin-top: 12px;
        font-weight: bold;
        font-size: 12px;
        line-height: 1;
        i {
          margin-right: 4px;
          color: #e9e9e9;
        }
        a {
          color: #e9e9e9;
        }
      }
    }
  }
  .map-wrap {
    flex: 1;
    position: relative;
    .search-area {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 8px;
      z-index: 99;
    }
  }
}
