.credit-modal {
	margin: 0 !important;
	width: 100vw;
	height: 100vh;
	max-width: 100vw;
	
	.modal-content {
		padding: 32px 40px;
		width: 400px;
		height: 100vh;
		background-color: #1C1C21;
		.credit-modal-item {
			padding-bottom: 20px;
			.col {
				padding: 4px 0;
			}
			.cmi-title {
				color: rgba(233, 233, 233, 0.5);
				font-size: 12px;
				line-height: 14px;
				font-weight: $bold;
			}
			.cmi-title-w156 {
				width: 156px;
			}
			.cmi-ml-5 {
				margin-left: 5px;
			}
			.cmi-order-total {
				height: 36px;
				background-color:#121217;
				border: 1px solid #121217;
				border-radius: 8px;
				padding: 0 18.75px;
				display: flex;
				align-items: center;
				color: #E9E9E9;
				font-size: 14px;
				cursor: pointer;
				i {
					font-size: 14px;
					color: rgba(233, 233, 233, 0.5);
					padding-right: 10.75px;
				}
				&:hover {
					i {
						color: rgba(233, 233, 233, 1);
					}
				}
			}
			.cmi-title-left {
				width: 180px;
				margin-right: 8px;
			}
			.cmi-receipt {
				margin-top: 8px;
				display: flex;
				.cmi-receipt-title {
					width: 180px;
					height: 36px;
					margin-right: 8px;
					border-radius: 8px;
					background-color: #121217;
					padding: 0 16px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.cmi-receipt-title-left {
						color: #E9E9E9;
						font-size: 14px;
					}
					.cmi-receipt-title-right {
						color: rgba(233, 233, 233, 0.5);;
						font-size: 14px;
					}
				}
				.cmi-receipt-price {
					width: 88px;
					height: 36px;
					padding: 0 16px;
					background-color: #121217;
					border-radius: 8px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					text-align: right;
				}
				.cmi-receipt-check {
					margin-left: 8px;
					width: 36px;
					height: 36px;
					background-color: #121217;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					i {
						font-size: 16px;
						color: rgba(233, 233, 233, 0.25);
					}
					.icon-action {
						color: #03C9A9;
					}
				}
			}
			.cmi-total {
				margin-top: 8px;
				width: 100%;
				height: 36px;
				background-color: #121217;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #03C9A9;
				font-size: 14px;
			}
		}
		.cmi-submit {
			padding-top: 24px;
			border-top: 1px dashed #363636;
			.cmi-submit-btn {
				width: 100%;
				height: 40px;
				margin-bottom: 24px;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: $bold;
				cursor: pointer;
			}
			.cmi-submit-request {
				background-color: #E9E9E9;
				color: #000016;
				i {
					color: #000016;
				}
			}
			.cmi-submit-approve {
				background-color: #008DFF;
				color: #E9E9E9;
				i {
					color: #E9E9E9;
				}
			}
			.cmi-submit-deny {
				border: 2px solid rgba(233, 233, 233, 0.25);
				background-color: #1C1C21;
				color: #ED5F65;
				i {
					color: #ED5F65;
				}
			}
			.cmi-submit-note {
				margin-bottom: 24px;
				.cmi-submit-note-title {
					font-size: 12px;
					line-height: 1;
					color: rgba(233, 233, 233, 0.5);
					padding-bottom: 8px;
				}
			}
			.cmi-submited-approve {
				.cmi-submited-approve-title {
					font-size: 12px;
					line-height: 1;
					color: #03C9A9;
					padding-bottom: 8px;
				}
			}
			.cmi-submited-denied {
				.cmi-submited-denied-title {
					font-size: 12px;
					line-height: 1;
					color: #ED5F65;
					padding-bottom: 8px;
				}
			}
			.cmi-submited-text {
				background-color: #121217;
				border-radius: 8px;
				padding: 10px 16px;
				color: #E9E9E9;
				font-size: 14px;
				line-height: 1.2;
			}
		}
	}
}