.status-flag {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: $dark;
    vertical-align: middle;
    margin-right: 10px;
  }
  &.status-yellow {
    &::before {
      background-color: $yellow;
    }
  }
}
@each $color, $value in $theme-colors {
  .status-#{$color} {
    &::before {
      background-color: $value;
    }
  }
}
