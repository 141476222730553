.flag-rect {
  width: 20px;
  height: 17px;
  left: -10px;
  z-index: 100;
  background-color: #000016;
  border: 1px solid #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 800;
}

.tier-line {
  position: absolute;
  left: -1px;
  top: 0;
  transform: translateY(-50%);
  border-left: 1px solid #e9e9e9;
  height: 100%;
  &.muted {
    border-left: 1px dashed $cream-25;
    z-index: 1;
  }
}

.tier-block {
  &:first-child {
    .tier-line {
      transform: translateY(0%);
      height: 50%;
    }
  }
  &:last-child {
    .tier-line {
      height: 150%;
      transform: translateY(-32%);
    }
  }
}

.circle-p {
  width: 8px;
  height: 8px;
  background-color: #e9e9e9;
  left: -5px;
  top: 35%;
  z-index: 2;
}

.circle-muted {
  z-index: 2;
  width: 8px;
  height: 8px;
  border: 1px solid $cream-25;
  background-color: $black;
  left: -5px;
  top: 35%;
}
