/* Change the white to any color ;) */
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border: 1px solid $field-color;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px $field-color inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $white;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px $black inset;
  transition: background-color 5000s ease-in-out 0s;
}

input {
  background-color: $field-color;
}

// input:-internal-autofill-selected {
//   appearance: menulist-button;
//   background-color: $field-color !important;
//   background-image: none !important;
//   color: -internal-light-dark(black, white) !important;
// }
.debounce-input {
  height: 36px;
  border-radius: 8px;
  width: 100%;
  color: $white;
  padding: 0 15px;
  border: 1px solid $field-color;
  text-overflow: ellipsis;
  &.onboarded {
    background-color: $disable-color;
    pointer-events: none;
  }
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $white;
    border-radius: 8px;
    outline: none;
  }
}

.width-200 {
  width: 200px;
}

.input-group-text {
  border: 0px;
  border-radius: 4px;
  background-color: #f4f6f8;
  border: none;
  font-size: 14px;
  height: 36px;
}

.input-group > .form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  // border-left-width: 0;
  text-align: right;
}

.input-group > .input-group-prepend:not(:first-child) {
  .input-group-text {
    border-left-width: 0;
  }
}

.input-group > .input-group-prepend:last-child {
  .input-group-text {
    border-radius: 0 8px 8px 0;
  }
}
.input-group > .input-group-prepend:first-child {
  .input-group-text {
    border-right-width: 0;
  }
}
