@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$bold: 700;
$semi-bold: 500;
$regular: 400;

$disable-color: #121217;
$black: #000016;
$field-color: #292a33;
$separator-color: #363636;
$card-color: #1c1c21;
$board-color: #1e1e21;

$cream-100: rgba(233, 233, 233, 1); //#E9E9E9
$cream-75: rgba(233, 233, 233, 0.75);
$cream-50: rgba(233, 233, 233, 0.5);
$cream-25: rgba(233, 233, 233, 0.25);

$gray: #0a0a10;
$white: #ffffff;
$yellow: #efc853;
$yellow2: #ffe200;
$orange: #f59762;
$blue: #008dff;
$purple: #825cff;
$green: #03c9a9;
$red: #ed5f65;

$blue: $blue;
$info: $blue;
$warning: $orange;
$danger: $red;

$border-color: #707070;
$border-color-light: $cream-100;

$theme-colors: (
  'black': $black,
);
