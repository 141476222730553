.container {
  width: 1360px !important;
  max-width: 1360px !important;
  padding: 0;
}

body {
  font-size: 14px;
  background-color: $black;
}

h1 {
  font-size: 32px;
  color: $cream-100;
  line-height: 1.3;
  text-align: center;
}

h4 {
  font-size: 14px;
  color: $cream-75;
  line-height: 1.3;
  text-align: center;
}

.main-content {
  position: relative;
  overflow-y: auto;

  .form-card {
    margin: 65px auto auto auto;
  }
}

.page-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

label {
  color: $cream-50;
  position: relative;
  font-weight: $bold;
  font-size: 12px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.divider {
  height: 0;
  display: block;
  width: 100%;
  border-top: 1px solid;
  margin: 1rem 0;
}

.w-action {
  width: 46px;
}

.w-6 {
  width: 6px;
}

.w-20px {
  width: 20px;
}

.w-80 {
  width: 80px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.w-160 {
  width: 160px;
}

.w-240 {
  width: 240px;
}

.w-400 {
  width: 400px;
}

.mw-120px {
  max-width: 120px;
}

.mw-160px {
  max-width: 160px;
}

.mw-200px {
  max-width: 200px;
}

.mw-220px {
  max-width: 220px;
}

.mw-180px {
  max-width: 180px;
}

.mw-100px {
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mw-400 {
  max-width: 400px;
}

.w-500 {
  width: 500px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25-percent {
  margin-top: 25%;
}

.mt-30-percent {
  margin-top: 30%;
}

.v-hidden {
  visibility: hidden;
}

.page-item {
  .page-link {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 26px;
    padding: 0;
    text-align: center;
    margin: 2.5px;
    text-decoration: none !important;
    background-color: $field-color;
    border: none;
    color: $white;
    &:hover {
      text-decoration: none !important;
    }
  }
  &.active .page-link {
    background-color: $white;
    color: $black;
  }
}

.pagination-sm {
  .page-item {
    &:first-child .page-link {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }

    &:last-child .page-link {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }
  }
}

.mt-30 {
  margin-top: 30px;
}

.alert-danger {
  background-color: $red;
  border-color: $red;
  color: $white;
  font-size: 16px;
  font-weight: $bold;
}

.ponit-events-none {
  pointer-events: none;
}
.cursor-none {
  cursor: none;
}
.red-circle {
  width: 8px !important;
  height: 8px !important;
  background-color: $red;
  border-radius: 50%;
}

.z-100 {
  z-index: 100;
}

.fixed-height-overflow-hidden {
  height: 100vh !important;
  overflow: hidden;
}

//Scroll bar CSS Define
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 $gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray;
}

//Custom Width definition

.min-w-178px {
  min-width: 178px;
}

.min-w-210px {
  min-width: 210px;
}

.min-w-600px {
  min-width: 600px;
}

.min-w-240px {
  min-width: 240px;
}

//Border
.custom-border {
  border: 1px solid #363636;
  border-radius: 5px;
}

.custom-border-left {
  border-left: 1px solid #838385;
}

.custom-border-left-2 {
  border-left: 2px solid #838385;
}

.custom-border-top {
  border-top: 1px solid #363636;
}

.custom-border-bottom {
  border-bottom: 1px solid #363636;
}

.custom-border-bottom-dashed {
  border-bottom: 1px dashed #363636;
}

.custom-border-bottom-dashed-cream {
  border-bottom: 1px dashed $cream-25;
}

.custom-border-dotted-top-bottom {
  border-top: 1px dashed #363636;
  border-bottom: 1px solid #363636;
}

//Algin
.custom-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

//Padding
.py-15px {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-29px {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

//Margin
.mt-15px {
  margin-top: 15px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mt-28px {
  margin-top: 28px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-15px {
  margin-right: 15px;
}

//Font Weight
.font-bold {
  font-weight: 700 !important;
}

//Font Size
.text-12px {
  font-size: 12px;
}

.text-14px {
  font-size: 14px !important;
}

.text-16px {
  font-size: 16px;
}

.text-24px {
  font-size: 24px;
}

.text-28px {
  font-size: 28px;
}

.text-32px {
  font-size: 32px;
}

//Color
.text-gray-70 {
  color: #707070;
}

.background-gray-12 {
  background-color: #121217;
}

.text-yellow-ef {
  color: #efc853;
}

//Custom Width
.w-6px {
  width: 6px;
}

.w-8px {
  width: 8px;
}

.w-36px {
  width: 36px;
}

.w-240px {
  width: 240px;
}

.w-360px {
  width: 360px;
}

.w-365px {
  width: 365px;
}

.w-944px {
  width: 944px;
}

//Custom Height
.h-6px {
  height: 6px;
}

.h-8px {
  height: 8px;
}

.h-20px {
  height: 20px;
}

.h-36px {
  height: 36px;
}

.h-230px {
  height: 230px;
}

// Border Color
.border-gray-cream-25 {
  border-color: $cream-25;
  border-width: 2px;
}

// Rounded Common Class
.rounded-8px {
  border-radius: 8px !important;
}

//font-weight
.text-bold {
  font-weight: 700;
}

.header-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  justify-content: space-between;
}

.spread-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loadingToast {
  position: fixed;
  right: 1rem;
  top: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  color: $cream-25;
}

.rectangle-banner {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.mr-6 {
  margin-right: 64px;
}

.text-elliosis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
