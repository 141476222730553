@import '~antd/dist/antd.css';

@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';

@import './common';
@import './text';
@import './layout';
@import './form';
@import './card';
@import './input';
@import './button';
@import './select';
@import './table';
@import './icon';
@import './modal';
@import './address_input';
@import './brandSearch';
@import './upload';
@import './support';
@import './nav';
@import './status';
@import './ant';
@import './leaderboard';
@import './date_range_picker';
@import './credit';
