.cutome-select-item-status {
  display: flex;
  align-items: center;
  height: 36px;
  &:hover {
    background-color: #292a33;
    cursor: pointer;
  }
  .item-status {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  .item-label {
    padding: 2px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #e9e9e9;
    font-weight: 700;
  }
}
